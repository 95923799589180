import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { DatePicker as AntdDatePicker } from "antd";
import StatisticsTable from "Components/show/overview/statistics_table";
import { lastDayOfMonth } from "date-fns";
import { toPercent } from "Helpers/index";
import i18n from "i18next";
import moment, { Moment } from "moment";
import momentConfig from "rc-picker/lib/generate/moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { POSITION_REPORT_DATES } from "../InvestmentPrivatePositionsTable/graphql";
import { FETCH_INVESTMENT_POSITIONS } from "./graphql";
import { styled } from "@mui/material";

const DatePicker = AntdDatePicker.generatePicker(momentConfig);

const PositionDetailsDiv = styled("div")`
  display: flex;
  justify-content: space-between;
  margin: 0 5px; /* Horizontal margin */
  align-items: center; /* Center items vertically */
`;

/* eslint-disable-next-line max-lines-per-function */
function InvestmentPositionsTable({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const [date, setDate] = useState<Date | null>(null);

  const { loading, error, data, fetchMore } = useQuery<
    types.InvestmentPrivatePositionsQuery,
    types.InvestmentPrivatePositionsQueryVariables
  >(FETCH_INVESTMENT_POSITIONS, {
    variables: {
      positionFilter: {
        reportDate: date,
      },
      page: {
        limit: 100,
      },
      sort: [
        {
          field: types.PositionSortEnum.Weight,
          order: types.SortInput.Desc,
        },
      ],
      id: investmentId,
    },
    notifyOnNetworkStatusChange: true,
    skip: !date,
  });

  // fetch more data while there is more data to fetch
  useEffect(() => {
    const fetchMoreData = async () => {
      if (data && data.investment && data.investment.positions) {
        const { items, total } = data.investment.positions;

        if (items.length < total) {
          await fetchMore({
            variables: {
              id: investmentId,
              positionFilter: {
                reportDate: date,
              },

              page: {
                limit: 100,
                offset: items.length, // Use current length for the next offset
              },
              sort: [
                {
                  field: types.PositionSortEnum.Weight,
                  order: types.SortInput.Desc,
                },
              ],
            },

            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult || !fetchMoreResult.investment) return prev;

              const newItems =
                fetchMoreResult?.investment?.positions?.items || [];

              return {
                investment: {
                  ...prev.investment,
                  positions: {
                    ...prev.investment.positions,
                    items: [
                      ...(prev?.investment?.positions?.items || []),
                      ...newItems,
                    ], // Merge items
                  },
                },
              } as types.InvestmentPrivatePositionsQuery;
            },
          });
        }
      }
    };

    if (date && !loading && !error) {
      fetchMoreData();
    }
  }, [data, date, loading, error, investmentId, fetchMore]);

  const { data: datesData, loading: datesLoading } = useQuery<
    types.GetPositionReportDatesQuery,
    types.GetPositionReportDatesQueryVariables
  >(POSITION_REPORT_DATES, {
    variables: {
      investmentId,
    },
  });

  useEffect(() => {
    if (datesData && datesData.positionReportDates[0]) {
      setDate(new Date(datesData.positionReportDates[0]));
    }
  }, [datesData]);

  const name = data?.investment?.name || "";
  const items = useMemo(() => data?.investment?.positions?.items || [], [data]);

  const updateDate = (newDate: moment.Moment | null) => {
    if (!newDate) return;
    const endOfMonth = lastDayOfMonth(newDate.toDate());
    setDate(endOfMonth);
  };

  const disabledTime = useCallback(
    (selectionDate: Moment) =>
      !datesData?.positionReportDates
        .map(dateDatum => new Date(dateDatum))
        .some(
          (dateDatum: Date) =>
            selectionDate.month() === dateDatum.getMonth() &&
            selectionDate.year() === dateDatum.getFullYear()
        ),
    [datesData]
  );

  const positions = useMemo(
    () =>
      items.map(item => ({
        ...item,
        weight: toPercent(item.weight),
      })),
    [items]
  );

  if (error) {
    return <span>Something went wrong fetching positions</span>;
  }

  return (
    <div>
      {error && (
        <div className="error">
          Something went wrong, please contact support.
          {JSON.stringify(error, null, 2)}
        </div>
      )}
      <div className="summary-heading" data-cy="positions">
        <h3 className="invt-tab__title" id="positions">
          {i18n.t("overview.positions")}
        </h3>

        <div className="summary-heading__desc-wrap">
          {(loading || datesLoading) && (
            <i
              data-testid="loading-spinner"
              className="fa fa-spinner fa-spin"
            />
          )}
          {date && (
            <>
              <span className="summary-heading__desc-label">
                {i18n.t("date.as_of", { date: "" })}
              </span>

              <div className="rc-calendar-custom-wrap width-120">
                <DatePicker
                  picker="month"
                  format="MMM yyyy"
                  disabledDate={disabledTime}
                  allowClear={false}
                  placeholder="Set the date"
                  defaultValue={moment(date)}
                  onChange={updateDate}
                  data-cy="month-picker"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div id="positions-table" className="positions-table">
        <PositionDetailsDiv>
          {positions.length > 0 &&
            `Source Date: ${positions[0].sourceDate.substring(0, 10)}`}
          {data && data.investment && data.investment.positions && (
            <span className="summary-heading__desc-label">
              {data.investment.positions.total ===
              data.investment.positions.items.length ? (
                <span>{data.investment.positions.total} positions</span>
              ) : (
                <span>
                  {data.investment.positions.items.length} of{" "}
                  {data.investment.positions.total} positions loading...
                </span>
              )}
            </span>
          )}
        </PositionDetailsDiv>

        <div>
          <div id="positions-table" className="positions-table">
            <StatisticsTable
              dataMapping={positions}
              keyOrder={["name", "ticker", "weight"]}
              headers={["Name", "Ticker", "Weighting"]}
              fileName={`${name} ${date?.getFullYear()} ${
                date ? date.getMonth() + 1 : ""
              } positions`}
              sortableKeys={["name", "ticker", "weight"]}
              numberKeys={["weight"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestmentPositionsTable;
