import { Table, TableColumnsType } from "antd";
import { AssetAllocationItem } from "./AllocationTable.component";

function ExpandableStatisticsTable({
  columns,
  loading,
  data,
  loadChildren,
  expandedRowKeys,
  setExpandedRowKeys,
}: {
  columns: TableColumnsType<AssetAllocationItem>;
  loading: boolean;
  data?: AssetAllocationItem[];
  loadChildren: (parent: AssetAllocationItem) => void;
  expandedRowKeys: string[];
  setExpandedRowKeys: (expandedRowKeys: string[]) => void;
}): JSX.Element {
  return (
    <Table
      data-cy="asset-allocation-table"
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={false}
      size="small"
      onRow={(record: AssetAllocationItem) => ({
        style: {
          background: record.key.startsWith("open-")
            ? "#DFDFFF"
            : record.key.startsWith("client-")
              ? "#EFEFFF"
              : record.key.startsWith("investment-")
                ? "#FFFFFF"
                : "#BFBFFF",
        },
      })}
      expandable={{
        expandedRowKeys,
        onExpand(expanded, record: AssetAllocationItem) {
          if (!expanded) {
            setExpandedRowKeys(expandedRowKeys.filter(x => x !== record.key));
            return;
          }
          setExpandedRowKeys([...expandedRowKeys, record.key]);
          if (record.childrenLoaded) return;
          loadChildren(record);
        },
      }}
    />
  );
}

export default ExpandableStatisticsTable;
