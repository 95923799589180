import { gql } from "_graphql-types/gql";

export const INVESTMENT_TYPE = gql(/* GraphQL */ `
  query InvestmentType($id: Int!) {
    investment(id: $id) {
      id
      name
      shortName
      firmId
      familyId
      isImpact
      isInActive
      inceptionDate
      isPortfolio
      access {
        read
        preview
      }
      portalSubmitted {
        id
        name
        migratedAt
      }
      strategy {
        id
        name
      }
      assetClass {
        id
        name
      }
      market {
        id
        name
      }
      investmentTags {
        id
        tagId
        tag {
          id
          name
        }
        tagClassId
        investmentId
      }
    }
  }
`);
