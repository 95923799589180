import _ from "lodash";
import { PUBLISH_FIELD_HEADING_HEIGHT } from "Helpers/dom_helpers";

export const height = state => {
  return _.get(state, "header.height", String());
};

export const heightValue = state =>
  Number(String(height(state)).replace(/[^0-9.]/g, ""));

export const state = state_ => _.get(state_, "header.state", String());

export const rawHeight = state_ =>
  _.toInteger(_.replace(height(state_), "px", ""));

export const anchorScrollOffsetTop = state_ =>
  rawHeight(state_) + PUBLISH_FIELD_HEADING_HEIGHT;
