import { ChatMessage } from "Components/ChatBox/types";
import { useReducer } from "react";
import {
  MessageStream,
  MessageStreamEvent,
  MessageType,
} from "src/graphql-types/graphql";

export interface UserFile {
  name: string;
  bucket: string;
  key: string;
}

export interface SessionAttributes {
  files?: string;
}

export function randomId() {
  return `${Date.now().toString(36)}-${Math.random().toString(36).slice(2)}`;
}

export class Session {
  readonly sessionId = randomId();
  constructor(public readonly llmOnly = false) {}
}

export type FocusState =
  | { type: "idle" }
  | {
      type: "streaming";
      requestId: string;
      messageId: string;
      message: string;
      blocks: MessageStream[];
    }
  | {
      type: "tickertape";
      requestId: string;
      messageId: string;
      message: string;
      words: string[];
      delay: number;
      effect: boolean;
    }
  | {
      type: "request";
      requestId: string;
      send: () => void;
      effect: boolean;
    }
  | {
      type: "fileUpload";
      batch: { file: File; result?: { ok: UserFile } | { error: any } }[];
      effect: boolean;
    };

export type ChatState = {
  session: Session;
  focus: FocusState;
  userText: string;
  conversationId?: number;
  finalMessages: ChatMessage[];
  sessionAttributes?: SessionAttributes;
};

export type ChatEvent =
  | {
      type: "insertTickertape";
      requestId: string;
      messageId: string;
      message: string;
    }
  | {
      type: "updateTickertape";
      requestId: string;
      messageId: string;
      message: string;
      words: string[];
      delay: number;
      effect: boolean;
    }
  | {
      type: "stream";
      requestId: string;
      message: MessageStream;
      error?: boolean | null;
    }
  | {
      type: "beginRequest";
      requestId: string;
      send: () => void;
      finalMessages?: ChatMessage[];
    }
  | {
      type: "awaitRequest";
      requestId: string;
      finalMessages?: ChatMessage[];
    }
  | {
      type: "chainRequest";
      requestId: string;
      begin: {
        requestId: string;
        send: () => void;
      };
      finalMessages?: ChatMessage[];
    }
  | { type: "endRequest"; requestId: string; finalMessages?: ChatMessage[] }
  | {
      type: "setSessionAttributes";
      sessionAttributes?: Record<string, string>;
    }
  | { type: "insertFile"; file: File; finalMessages?: ChatMessage[] }
  | {
      type: "updateFile";
      file: File;
      result: { ok: UserFile } | { error: any };
      finalMessages?: ChatMessage[];
    }
  | { type: "endFileUpload"; finalMessages?: ChatMessage[] }
  | { type: "stop" }
  | { type: "newSession"; llmOnly?: boolean }
  | { type: "setConversationId"; conversationId: number }
  | { type: "setUserText"; userText: string };

export function useChatReducer() {
  return useReducer(reduce, false, initial);
}

function initial(llmOnly: boolean): ChatState {
  return {
    session: new Session(llmOnly),
    userText: "",
    conversationId: void 0,
    finalMessages: [],
    sessionAttributes: {},
    focus: { type: "idle" },
  };
}

function assertNever(x: never): never {
  throw new Error("Unexpected case: " + JSON.stringify(x));
}

function reduce(state: ChatState, event: ChatEvent): ChatState {
  switch (event.type) {
    case "endFileUpload":
      return reduceEndFileUpload(state, event);
    case "insertFile":
      return reduceInsertFile(state, event);
    case "beginRequest":
      return reduceBeginRequest(state, event);
    case "awaitRequest":
      return reduceAwaitRequest(state, event);
    case "chainRequest":
      return reduceChainRequest(state, event);
    case "endRequest":
      return reduceEndRequest(state, event);
    case "insertTickertape":
      return reduceInsertTickertape(state, event);
    case "newSession":
      return reduceNewSession(state, event);
    case "stop":
      return reduceStop(state, event);
    case "setConversationId":
      return reduceSetConversationId(state, event);
    case "setSessionAttributes":
      return reduceSetSessionAttributes(state, event);
    case "setUserText":
      return reduceSetUserText(state, event);
    case "stream":
      return reduceStream(state, event);
    case "updateFile":
      return reduceUpdateFile(state, event);
    case "updateTickertape":
      return reduceUpdateTickertape(state, event);
    default:
      assertNever(event);
  }
}

function reduceEndFileUpload(
  state: ChatState,
  event: Extract<ChatEvent, { type: "endFileUpload" }>
): ChatState {
  if (state.focus.type === "fileUpload") {
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...(event.finalMessages ?? [])],
      focus: { type: "idle" },
    };
  } else {
    return state;
  }
}

function reduceChainRequest(
  state: ChatState,
  event: Extract<ChatEvent, { type: "chainRequest" }>
): ChatState {
  if ("requestId" in state.focus && state.focus.requestId === event.requestId) {
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...(event.finalMessages ?? [])],
      focus: {
        type: "request",
        ...event.begin,
        effect: true,
      },
    };
  } else {
    return state;
  }
}

function reduceEndRequest(
  state: ChatState,
  event: Extract<ChatEvent, { type: "endRequest" }>
): ChatState {
  if (
    state.focus.type === "streaming" &&
    state.focus.requestId === event.requestId
  ) {
    const finalMessages = event.finalMessages ?? [];
    finalMessages.push({
      type: "agent",
      data: { type: "markdown", message: state.focus.message },
    });
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...finalMessages],
      focus: { type: "idle" },
    };
  } else if (
    state.focus.type === "tickertape" &&
    state.focus.requestId === event.requestId
  ) {
    const finalMessages = event.finalMessages ?? [];
    finalMessages.push({
      type: "agent",
      data: { type: "markdown", message: state.focus.message },
    });
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...finalMessages],
      focus: { type: "idle" },
    };
  } else if (
    state.focus.type === "request" &&
    state.focus.requestId === event.requestId
  ) {
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...(event.finalMessages ?? [])],
      focus: { type: "idle" },
    };
  } else {
    return state;
  }
}

function reduceInsertFile(
  state: ChatState,
  event: Extract<ChatEvent, { type: "insertFile" }>
): ChatState {
  if (state.focus.type === "idle") {
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...(event.finalMessages ?? [])],
      focus: {
        type: "fileUpload",
        batch: [{ file: event.file }],
        effect: false,
      },
    };
  } else if (state.focus.type === "fileUpload") {
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...(event.finalMessages ?? [])],
      focus: {
        type: "fileUpload",
        batch: [...state.focus.batch, { file: event.file }],
        effect: false,
      },
    };
  } else {
    return state;
  }
}

function reduceUpdateFile(
  state: ChatState,
  event: Extract<ChatEvent, { type: "updateFile" }>
): ChatState {
  console.log("reduceUpdateFile", state, event);
  if (state.focus.type === "fileUpload") {
    let { sessionAttributes } = state;
    if ("ok" in event.result) {
      const oldFiles = JSON.parse(
        sessionAttributes?.files ?? "[]"
      ) as UserFile[];

      const newFiles = [...oldFiles, event.result.ok];

      sessionAttributes = {
        ...sessionAttributes,
        files: JSON.stringify(newFiles),
      };
    }

    const batch = state.focus.batch.map(item =>
      item.file === event.file ? { ...item, result: event.result } : item
    );
    const effect = !!batch.length && batch.every(item => item.result);

    console.log({ batch, effect });

    const finalMessages = event.finalMessages ?? [];

    if (effect) {
      if (batch.length > 1) {
        // summary message
        finalMessages.push({
          type: "agent",
          data: {
            type: "markdown",
            message: `Uploaded ${batch.length} files.`,
          },
        });
      }
      return {
        ...state,
        sessionAttributes,
        finalMessages: [...state.finalMessages, ...finalMessages],
        focus: {
          type: "fileUpload",
          batch,
          effect,
        },
      };
    } else {
      if (batch.length > 1) {
        // summary message
        finalMessages.push({
          type: "agent",
          data: {
            type: "markdown",
            message: `Failed to upload all ${batch.length} files.`,
          },
        });
      }
      return {
        ...state,
        finalMessages: [...state.finalMessages, ...finalMessages],
        focus: { type: "idle" },
      };
    }
  } else {
    return state;
  }
}

function reduceNewSession(
  state: ChatState,
  event: Extract<ChatEvent, { type: "newSession" }>
): ChatState {
  return initial(event.llmOnly ?? state.session.llmOnly);
}

function reduceStop(
  state: ChatState,
  event: Extract<ChatEvent, { type: "stop" }>
): ChatState {
  if (state.focus.type === "streaming") {
    return reduceEndRequest(state, {
      type: "endRequest",
      requestId: state.focus.requestId,
    });
  } else if (state.focus.type === "tickertape") {
    return reduceEndRequest(state, {
      type: "endRequest",
      requestId: state.focus.requestId,
    });
  } else {
    return {
      ...state,
      focus: { type: "idle" },
    };
  }
}

function reduceBeginRequest(
  state: ChatState,
  event: Extract<ChatEvent, { type: "beginRequest" }>
): ChatState {
  if (state.focus.type === "idle" || state.focus.type === "fileUpload") {
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...(event.finalMessages ?? [])],
      focus: {
        type: "request",
        requestId: event.requestId,
        send: event.send,
        effect: true,
      },
    };
  } else {
    return state;
  }
}

function reduceAwaitRequest(
  state: ChatState,
  event: Extract<ChatEvent, { type: "awaitRequest" }>
): ChatState {
  if (
    state.focus.type === "request" &&
    state.focus.requestId === event.requestId &&
    state.focus.effect
  ) {
    const finalMessages = event.finalMessages ?? [];
    return {
      ...state,
      finalMessages: [...state.finalMessages, ...finalMessages],
      focus: {
        ...state.focus,
        effect: false,
      },
    };
  } else {
    return state;
  }
}

function reduceSetConversationId(
  state: ChatState,
  event: Extract<ChatEvent, { type: "setConversationId" }>
): ChatState {
  return {
    ...state,
    conversationId: event.conversationId,
  };
}

function reduceSetSessionAttributes(
  state: ChatState,
  event: Extract<ChatEvent, { type: "setSessionAttributes" }>
): ChatState {
  return {
    ...state,
    sessionAttributes: event.sessionAttributes,
  };
}

function reduceSetUserText(
  state: ChatState,
  event: Extract<ChatEvent, { type: "setUserText" }>
): ChatState {
  return {
    ...state,
    userText: event.userText,
  };
}

function reduceStream(
  state: ChatState,
  event: Extract<ChatEvent, { type: "stream" }>
): ChatState {
  if (
    (state.focus.type === "request" || state.focus.type === "streaming") &&
    state.focus.requestId === event.requestId &&
    event.message.event !== MessageStreamEvent.Start
  ) {
    if (
      event.message.contentType &&
      event.message.contentType !== MessageType.PlainText
    ) {
      throw Error("Streaming message should contain plain text type: " + event);
    }

    let message = "";
    const finalMessages: ChatMessage[] = [];
    if (state.focus.type === "streaming") {
      if (state.focus.messageId === event.message.id) {
        message = state.focus.message;
      } else {
        finalMessages.push({
          type: "agent",
          data: { type: "markdown", message: state.focus.message },
        });
      }
    }

    if (event.message.event === MessageStreamEvent.Stop) {
      if (state.focus.type === "request") {
        // we went directly from a request to a stream stop event
        // that means the stream is coming from a non-streaming source
        // simulate a streaming response using tickertape, per requirements
        if (event.error) {
          return reduce(state, {
            type: "endRequest",
            requestId: event.requestId,
            finalMessages: [
              {
                type: "agent",
                id: event.message.id,
                data: {
                  type: "error",
                  message: event.message.message ?? "",
                },
              },
            ],
          });
        } else {
          return reduce(state, {
            type: "insertTickertape",
            requestId: event.requestId,
            messageId: event.message.id,
            message: event.message.message ?? "",
          });
        }
      } else {
        finalMessages.push({
          type: "agent",
          data: {
            type: "markdown",
            // the final message should have the full text, but just in case
            message: event.message.message || message,
          },
        });
        // Assumption: Upon the first final message, we assume the message stream
        // is complete. That might not be true in some cases.
        return {
          ...state,
          finalMessages: [...state.finalMessages, ...finalMessages],
          focus: { type: "idle" },
        };
      }
    }

    let blocks: MessageStream[] =
      state.focus.type === "streaming" &&
      state.focus.requestId === event.requestId
        ? [...state.focus.blocks]
        : [];

    if (event.message.event === MessageStreamEvent.Message) {
      const blockIndex = event.message.blockIndex ?? 0;
      if (blockIndex >= blocks.length) {
        // common case
        blocks[blockIndex] = event.message;
        message += event.message.message;
      } else {
        // rare case
        blocks[blockIndex] = event.message;
        message = blocks.reduce((acc, item) => (acc += item.message), "");
      }
    }

    return {
      ...state,
      finalMessages: [...state.finalMessages, ...finalMessages],
      focus: {
        type: "streaming",
        requestId: event.requestId,
        messageId: event.message.id,
        message,
        blocks,
      },
    };
  } else {
    return state;
  }
}

function reduceInsertTickertape(
  state: ChatState,
  event: Extract<ChatEvent, { type: "insertTickertape" }>
): ChatState {
  if (
    state.focus.type === "idle" ||
    (state.focus.type === "request" &&
      state.focus.requestId === event.requestId)
  ) {
    const words = event.message.split(" ");
    const delay = Math.min(20, 5000 / event.message.length);
    return {
      ...state,
      focus: {
        type: "tickertape",
        requestId: event.requestId,
        messageId: event.messageId,
        message: "",
        words,
        delay,
        effect: true,
      },
    };
  } else {
    return state;
  }
}

function reduceUpdateTickertape(
  state: ChatState,
  event: Extract<ChatEvent, { type: "updateTickertape" }>
): ChatState {
  if (
    state.focus.type === "tickertape" &&
    state.focus.requestId === event.requestId &&
    state.focus.messageId === event.messageId
  ) {
    return {
      ...state,
      focus: {
        ...state.focus,
        message: event.message,
        words: event.words,
        delay: event.delay,
        effect: event.effect,
      },
    };
  } else {
    return state;
  }
}
