import React from "react";
import { range } from "lodash";
import { Slider } from "antd";

const ExposureSlider = ({
  maxLevel,
  level,
  onAfterChange,
}: {
  maxLevel: number;
  level: number;
  onAfterChange: any;
}) => {
  const marks = () => {
    const levelRange = range(1, maxLevel + 1);
    return levelRange.reduce((acc, i) => {
      acc[i] = "";
      return acc;
    }, {} as any);
  };

  return (
    <div className="chart-controls--slider">
      <div className="slider">
        <Slider
          marks={marks()}
          tipFormatter={(value?: number) => (value ? `Depth ${value - 1}` : "")}
          onChange={onAfterChange}
          defaultValue={1}
          value={level}
          step={1}
          min={2}
          max={maxLevel}
        />
      </div>
    </div>
  );
};

export default ExposureSlider;
