import classNames from "classnames";
import createClass from "create-react-class";
import { throttle } from "lodash";
import PropTypes from "prop-types";

import { ResizeSensor } from "css-element-queries";

const FadingHeader = createClass({
  displayName: "Common.FadingHeader",

  propTypes: {
    disable: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.node,
  },

  getDefaultProps() {
    return {
      disable: false,
      onChange: () => {},
      children: [<></>, false],
    };
  },

  getInitialState() {
    this.currentScrollY = 0;
    this.lastKnownScrollY = 0;
    this.scrollDirection = "";
    return {
      position: "unfixed",
      headerHeigth: 0,
    };
  },

  componentDidMount() {
    this._handleScroll = throttle(this.updateScrollDirection, 100);

    if (!this.props.disable) {
      this._addEventListeners();
    }

    this.handleResize();
  },

  componentWillUnmount() {
    this._removeEventListeners();
  },

  componentDidUpdate(nextProps) {
    if (nextProps.disable && !this.props.disable) {
      this._removeEventListeners();
    } else if (!nextProps.disable && this.props.disable) {
      this._addEventListeners();
    }
  },

  _addEventListeners() {
    this.resizeSensor = new ResizeSensor(this.mainHeader, this.handleResize);

    window.addEventListener("scroll", this._handleScroll);
  },

  _removeEventListeners() {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }
    window.removeEventListener("scroll", this._handleScroll);
  },

  handleResize() {
    this.setState({
      headerHeigth: this.getHeaderHeight(),
    });
  },

  updateScrollDirection() {
    const currentScrollY = window.scrollY;
    this.scrollDirection =
      currentScrollY >= this.lastKnownScrollY ? "down" : "up";

    if (this.isScrolledUp()) {
      this.pin();
    } else if (this.isScrolledDown()) {
      this.unpin();
    } else {
      this.unfix();
    }

    this.lastKnownScrollY = currentScrollY;
  },

  pin() {
    if (this.state.position !== "pinned") {
      this.setState({ position: "pinned" });
      this.props.onChange(this.state.position);
    }
  },

  unpin() {
    if (this.state.position !== "unpinned") {
      this.setState({ position: "unpinned" });
      this.props.onChange(this.state.position);
    }
  },

  unfix() {
    if (this.position !== "unfixed") {
      this.setState({ position: "unfixed" });
      this.props.onChange(this.state.position);
    }
  },

  isScrolledUp() {
    return this.scrollDirection === "up" && window.scrollY !== 0;
  },

  isScrolledDown() {
    return (
      this.scrollDirection === "down" &&
      window.scrollY > this.state.headerHeigth
    );
  },

  getHeaderHeight() {
    return this.mainHeader.getBoundingClientRect().height;
  },

  getWrapperStyles() {
    if (!this.state.headerHeigth) {
      return {};
    }
    return {
      height: `${this.state.headerHeigth}px`,
    };
  },

  render() {
    const { position } = this.state;

    return (
      <div
        style={this.getWrapperStyles()}
        className={classNames("main-header-wrap", {
          "main-header-wrap--scrolled-up": position === "pinned",
          "main-header-wrap--scrolled": position !== "unfixed",
        })}
      >
        <div
          className="main-header"
          ref={el => {
            this.mainHeader = el;
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  },
});

export default FadingHeader;
