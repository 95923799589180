import React, { useState, useCallback, useMemo, useEffect } from "react";
import * as types from "_graphql-types/graphql";

import { Affix, DatePicker, Tooltip } from "antd";
import { useQuery } from "@apollo/client";
import { GET_RELATIVE_DATE_RANGES, GET_RELATIVE_STATISTICS } from "./graphql";
import { parseISO, format, subYears, subMonths, isBefore } from "date-fns";
import { RelativePerformanceContext } from "./context";
import MonthlyReturnsChart from "./MonthlyReturnsChart";
import BenchmarkTable from "./BenchmarkTable";
import ComparitiveStatsChart from "./ComparativeStatsChart";
import RiskVsReturnChart from "./RiskVsReturnsChart";
import StatsChart from "./StatsChart";
import dayjs, { type Dayjs } from "dayjs";

const RelativePerformance = ({
  investmentId,
  performanceLag, // performanceLag should probably be database driven?
  children,
}: {
  investmentId: number;
  performanceLag?: { month: number; year: number };
  children: React.ReactNode;
}) => {
  const [selectedEndDate, selectEndDate] = useState<Dayjs | null>();

  const { data: relativeDateData } = useQuery(GET_RELATIVE_DATE_RANGES, {
    variables: { investmentId },
  });

  const endSubjectInvestmentPeriodRangeWithLag = useMemo(() => {
    if (!relativeDateData?.investment?.performancePeriodRange?.end) {
      return null;
    }

    return performanceLag
      ? new Date(performanceLag.year, performanceLag.month - 1).toISOString()
      : relativeDateData?.investment?.performancePeriodRange?.end;
  }, [performanceLag, relativeDateData]);

  const [minSharedDate, maxSharedDate] = useMemo(() => {
    let minDate: Date | null = null;
    let maxDate: Date | null = null;

    [
      relativeDateData?.investment?.performancePeriodRange?.start,
      relativeDateData?.investment?.primaryBenchmark?.performancePeriodRange
        ?.start,
    ]
      .filter(Boolean)
      .map(date => parseISO(date.replace("Z", "")))
      .forEach(date => {
        if (!minDate || date > minDate) {
          minDate = date;
        }
      });

    [
      endSubjectInvestmentPeriodRangeWithLag,
      relativeDateData?.investment?.primaryBenchmark?.performancePeriodRange
        ?.end,
    ]
      .filter(Boolean)
      .map(date => parseISO(date.replace("Z", "")))
      .forEach(date => {
        if (!maxDate || date < maxDate) {
          maxDate = date;
        }
      });

    return [minDate, maxDate];
  }, [relativeDateData, endSubjectInvestmentPeriodRangeWithLag]);

  useEffect(() => {
    if (relativeDateData && (minSharedDate || maxSharedDate)) {
      selectEndDate(maxSharedDate ? dayjs(maxSharedDate) : dayjs(new Date()));
    }
  }, [minSharedDate, maxSharedDate]);

  const {
    data: relativeStatisticsData,
    loading: relativeStatisticsLoading,
    error: relativeStatisticsError,
  } = useQuery(GET_RELATIVE_STATISTICS, {
    variables: {
      investmentId,
      endDate: selectedEndDate?.toDate()?.toISOString(),
      asOfDate: selectedEndDate?.toDate()?.toISOString().slice(0, 10),
      rangeOffset: undefined,
      rangeUnit: types.StatsRangeUnit.I,
      primaryBenchmarkId: relativeDateData?.investment?.primaryBenchmark?.id,
      secondaryBenchmarkId:
        relativeDateData?.investment?.secondaryBenchmark?.id,
    },
    skip: !selectedEndDate?.toDate() || !relativeDateData,
  });

  if (!relativeDateData || !minSharedDate || !maxSharedDate) {
    return null;
  }

  if (!relativeDateData?.investment?.performancePeriodRange?.start) {
    return <div>No performance data available</div>;
  }
  if (relativeStatisticsError)
    return <div>Error loading relative performance.</div>;

  return (
    <div id="relative-performance-subtab-page">
      <div>
        <Affix offsetTop={200}>
          <Tooltip
            title={`Select an as of date, 
              defaults to shared benchmark date by default - ${format(
                maxSharedDate,
                "yyyy-MM"
              )}`}
          >
            <DatePicker
              picker={"month"}
              value={selectedEndDate}
              onChange={selectEndDate as any}
              minDate={dayjs(
                parseISO(
                  relativeDateData?.investment?.performancePeriodRange?.start.replace(
                    "Z",
                    ""
                  )
                )
              )}
              maxDate={dayjs(
                parseISO(
                  endSubjectInvestmentPeriodRangeWithLag?.replace("Z", "")
                )
              )}
              allowClear={false}
            />
          </Tooltip>
        </Affix>

        <RelativePerformanceContext.Provider
          value={{
            investment: relativeStatisticsData?.investment,
            loading: relativeStatisticsLoading,
            isActiveContext: true,
          }}
        >
          {children}
        </RelativePerformanceContext.Provider>
      </div>
    </div>
  );
};

RelativePerformance.MonthlyReturnsChart = MonthlyReturnsChart;
RelativePerformance.BenchmarkTable = BenchmarkTable;
RelativePerformance.ComparitiveStatsChart = ComparitiveStatsChart;
RelativePerformance.RiskVsReturnChart = RiskVsReturnChart;
RelativePerformance.StatsChart = StatsChart;
export default RelativePerformance;
