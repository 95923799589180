/* eslint-disable max-lines-per-function */
import { useApolloClient } from "@apollo/client";
import { AssetAllocationTable } from "Components/AssetAllocation";
import { Authorized } from "Components/Authorized";
import { isAuthorized } from "Components/Authorized/authHelpers";
import { PeersTable } from "Components/show/analytics/PeersTable.component";
import Classifications from "Components/show/overview/Classifications";
import InvestmentServiceProviders from "Components/show/overview/InvestmentServiceProviders";
import InvestmentSummaryTile from "Components/show/overview/InvestmentSummaryTile";
import LiquidationTile from "Components/show/overview/LiquidationTile";
import Status from "Components/show/overview/Status";
import SummaryContactTile from "Components/show/overview/SummaryContactTile";
import SummaryFundAdvisor from "Components/show/overview/SummaryFundAdvisor";
import SummaryFundTile from "Components/show/overview/SummaryFundTile";
import { Tabs } from "antd";
import { useCurrentUser } from "frontend/src/utils/hooks";
import i18n from "i18next";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClientVisibleTabs } from "src/graphql-types/graphql";
import { HfInvestmentTerms } from "../../InvestmentTerms";
import { PortfolioPerformance } from "./PortfolioPerformance";
import { GET_PORTFOLIO_CONFIG } from "./graphql";

interface Props {
  investmentId: number;
}

const ALL_TABS_VISIBLE = "ALL_TABS_VISIBLE" as const;

const { TabPane } = Tabs;

const PortfolioOverview = ({ investmentId }: Props): JSX.Element => {
  const { hash, pathname } = useLocation();
  const client = useApolloClient();
  const navigate = useNavigate();

  const subtabRoute = (subtab: string) => {
    navigate(`${pathname}#${subtab}`);
  };

  const portfolioConfig = client.readQuery({
    variables: { id: investmentId },
    query: GET_PORTFOLIO_CONFIG,
  });

  const user = useCurrentUser();

  const clientVisibleTabs =
    !user.clientView ||
    !portfolioConfig?.porfolioConfigByPortfolioId?.clientVisibleTabs
      ? ALL_TABS_VISIBLE
      : portfolioConfig?.porfolioConfigByPortfolioId?.clientVisibleTabs;

  return (
    <div className="main-content main-content--lg">
      <Tabs
        activeKey={!hash ? "general" : hash.replace("#", "")}
        data-cy="portfolio-tabs"
        onChange={subtabRoute}
      >
        {(clientVisibleTabs === ALL_TABS_VISIBLE ||
          clientVisibleTabs.includes(ClientVisibleTabs.OverviewGeneral)) && (
          <TabPane tab={i18n.t("overview.general")} key="general">
            <div className="overview-page__top-info">
              <div className="overview-page__top-info-left">
                <InvestmentSummaryTile investmentId={investmentId} />
                <div className="invt-tab__section hidden-md-up">
                  <SummaryFundAdvisor
                    investmentId={investmentId}
                    className="fund-advisor--mobile"
                  />
                </div>
                <Authorized checks={["isRockCreek"]}>
                  <Classifications investmentId={investmentId} />
                  <Status investmentId={investmentId} />
                  <LiquidationTile investmentId={investmentId} />
                </Authorized>
                <div className="d-flex flex-wrap">
                  <SummaryFundTile
                    investmentId={investmentId}
                    header="Portfolio"
                    rcgOnlyFields={[
                      "Investment AUM",
                      "Strategy AUM",
                      "Gross Exposure Range",
                      "Net Exposure Range",
                      "Expected Volatility Range",
                      "Is Sub-Advised",
                    ]}
                  />
                </div>
                <div className="invt-tab__section">
                  <SummaryContactTile investmentId={investmentId} />
                </div>
              </div>
              <Authorized checks={["isRockCreek"]}>
                <div className="overview-page__top-info-right hidden-md-down">
                  <SummaryFundAdvisor investmentId={investmentId} />
                </div>
              </Authorized>
            </div>
          </TabPane>
        )}

        {(clientVisibleTabs === ALL_TABS_VISIBLE ||
          clientVisibleTabs.includes(
            ClientVisibleTabs.OverviewPerformance
          )) && (
          <TabPane tab={i18n.t("overview.performance")} key="performance">
            <PortfolioPerformance investmentId={investmentId} />
            {/* <Performance investmentId={investmentId} /> */}
          </TabPane>
        )}

        {(clientVisibleTabs === ALL_TABS_VISIBLE ||
          clientVisibleTabs.includes(ClientVisibleTabs.OverviewPorfolio)) && (
          <TabPane tab={i18n.t("overview.portfolio")} key="portfolio">
            <div className="invt-tab__section">
              <AssetAllocationTable investmentId={investmentId} />
            </div>
          </TabPane>
        )}

        {isAuthorized({ user, checks: ["isRockCreek"] }) ? (
          <React.Fragment key="hiddenPortfolioTabs-rcgOnly">
            <TabPane tab={i18n.t("overview.peers")} key="peers">
              <div className="invt-tab__section" style={{ maxWidth: "1500px" }}>
                <PeersTable investmentId={investmentId} />
              </div>
            </TabPane>
            <TabPane tab={i18n.t("overview.terms")} key="terms">
              <div className="invt-tab__section">
                <HfInvestmentTerms investmentId={investmentId} />
              </div>
            </TabPane>
          </React.Fragment>
        ) : undefined}

        {(clientVisibleTabs === ALL_TABS_VISIBLE ||
          clientVisibleTabs.includes(
            ClientVisibleTabs.OverviewServiceProviders
          )) && (
          <TabPane
            tab={i18n.t("overview.service_providers")}
            key="service_providers"
          >
            <div className="invt-tab__section">
              <InvestmentServiceProviders investmentId={investmentId} />
            </div>
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default PortfolioOverview;
