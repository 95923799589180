import { gql } from "_graphql-types/gql";

export const FETCH_PORTFOLIO_BALANCES = gql(/* GraphQL */ `
  query getPortfolioBalances($portfolioId: Int!, $date: Date!) {
    balancesList(
      filter: {
        portfolioId: $portfolioId
        modelStartDate: $date
        modelStopDate: $date
        includeAccountingEntities: true
      }
      sort: [{ field: date, order: DESC }]
    ) {
      items {
        date
        endBalance
        managerId
        manager {
          id
          name
          isEntitled
          primaryPortfolio {
            id
            name
          }
        }
        tags {
          tag
          tag2
          tag3
          tag4
        }
      }
    }
  }
`);

export const FETCH_ACCOUNT_MANAGER_DATA = gql(/* GraphQL */ `
  query getAccountingManagerData {
    accountingManagerList {
      items {
        id
        name
      }
    }
  }
`);

export const ALLOCATION_BALANCE_DATES = gql(/* GraphQL */ `
  query getAllocationBalanceDates($portfolioId: Int!) {
    balanceDates(portfolioId: $portfolioId)
  }
`);

export const FETCH_PORTFOLIO_DATA = gql(/* GraphQL */ `
  query getPortfolioData($filter: PortfolioFilter!) {
    portfolioList(filter: $filter) {
      items {
        investmentId
        id
        name
      }
    }
  }
`);

export const INVESTMENT_PRIMARY_PORTFOLIO = gql(/* GraphQL */ `
  query getInvestmentPrimaryPortfolio($investmentId: Int!) {
    investment(id: $investmentId) {
      primaryPortfolio {
        id
        name
      }
    }
  }
`);
