import React, { useMemo } from "react";
import { subDays, subWeeks, subYears, subQuarters } from "date-fns";
import { gql } from "_graphql-types/gql";
import { useApolloClient, useQuery } from "@apollo/client";
import Irr from "Components/Performance/PrivatePerformance";
import RelativePerformance from "../analytics/components/RelativePerformance";

import { GET_PORTFOLIO_CONFIG } from "./graphql";

const INVESTMENT_SUMMARY = gql(/* GraphQL */ `
  fragment InvestmentSummaryAttributeFragment on Investment {
    name
    id
    assetClass {
      id
      name
    }
    market {
      id
      name
    }
  }
`);

export function PortfolioPerformance({
  investmentId,
}: {
  investmentId: number;
}) {
  const client = useApolloClient();
  const investmentDetails = client.readFragment({
    id: `Investment:${investmentId}`,
    fragment: INVESTMENT_SUMMARY,
  });
  const { data } = useQuery(GET_PORTFOLIO_CONFIG, {
    variables: { id: investmentId },
  });

  const performanceLag = useMemo<
    { month: number; year: number } | undefined
  >(() => {
    let endDate: Date | undefined = undefined;
    switch (data?.porfolioConfigByPortfolioId?.performanceLag?.lagUnit.name) {
      case "Day":
        endDate = subDays(
          new Date(),
          data?.porfolioConfigByPortfolioId?.performanceLag?.lag
        );
        break;
      case "Week":
        endDate = subWeeks(
          new Date(),
          data?.porfolioConfigByPortfolioId?.performanceLag?.lag
        );
        break;
      case "Month":
        endDate = subYears(
          new Date(),
          data?.porfolioConfigByPortfolioId?.performanceLag?.lag
        );
        break;
      case "Quarter":
        endDate = subQuarters(
          new Date(),
          data?.porfolioConfigByPortfolioId?.performanceLag?.lag
        );
        break;
      case "Year":
        endDate = subYears(
          new Date(),
          data?.porfolioConfigByPortfolioId?.performanceLag?.lag
        );
        break;
    }

    if (endDate) {
      return {
        month: endDate.getMonth(),
        year: endDate.getFullYear(),
      };
    }

    return undefined;
  }, [data]);

  return (
    <>
      <div className="heading" data-cy="performance-heading">
        {I18n.t("overview.performance")}
      </div>
      {investmentDetails?.market?.id === 1 && (
        <RelativePerformance
          investmentId={investmentId}
          performanceLag={performanceLag}
        >
          <RelativePerformance.BenchmarkTable />
          <RelativePerformance.MonthlyReturnsChart />
        </RelativePerformance>
      )}
      {investmentDetails?.market?.id === 2 && (
        <Irr investmentId={investmentId} />
      )}
    </>
  );
}
