import React from "react";
import * as types from "_graphql-types/graphql";
import { InfiniteScroller } from "Components/InfiniteScroller";
import NotesCategoriesFilter from "./NotesCategoriesFilter";
import NotesFilters from "./NotesFilters";
import NotesList from "./NotesList";
import { notesIndexContext, useNotesIndex } from "./NotesIndex.context";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { Affix } from "antd";

const StyledSidebar = styled("div")({
  height: "calc(100vh - 200px)",
  overflow: "scroll",
});

export function NotesIndex({
  notesFilters,
  children,
}: {
  notesFilters?: types.NotesFilter;
  children?: JSX.Element;
}): JSX.Element {
  const notesIndexContextValue = useNotesIndex({ notesFilters });

  return (
    <notesIndexContext.Provider value={notesIndexContextValue}>
      <div id="fund-notes-body" className="fund-notes__body">
        <div>
          <Grid container spacing={2}>
            <Grid container item xs={3} direction="column">
              <div>
                <Affix offsetTop={200}>
                  <StyledSidebar>
                    <NotesCategoriesFilter />
                  </StyledSidebar>
                </Affix>
              </div>
            </Grid>
            <Grid container item xs={9} direction="column">
              <div className="fund-notes__main-wrap">
                <NotesFilters />
                {children}
                {notesIndexContextValue.error && (
                  <div className="notes-errors">
                    {JSON.stringify(notesIndexContextValue.error)}
                  </div>
                )}
                {notesIndexContextValue.notes && (
                  <NotesList notes={notesIndexContextValue.notes} />
                )}
              </div>
              <InfiniteScroller
                isLoading={notesIndexContextValue.loading}
                hasMore={!!notesIndexContextValue?.nextPage?.hasMore}
                loadMore={notesIndexContextValue.fetchMore}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </notesIndexContext.Provider>
  );
}
